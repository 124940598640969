import React, { useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import * as steelseries from "steelseries";
import { utcToMtyConvertionDate } from "../../shared/DateTimeConvertion";
import LcdDisplay from "../LCD";
import { getMax, getMin } from "../../shared/DataStructure";
import amcharts from "@amcharts/amcharts4/.internal/themes/amcharts";

import { BrowserView, MobileView } from 'react-device-detect';

const moment = require("moment-timezone");

function LineChart({
    index,
    plot_limit,
    limit_low,
    limit_high,
    limit_low_head,
    limit_high_head,
    data,
    label,
    device_id,
    parameter,
    newData,
    unit,
    minDate,
    limit_low_parameter,
    limit_high_parameter,
    sensor_set,
    newSinglePropData
}) {
    let chart;
    let led;
    let dateAxis;
    let valueAxis;
    let legenddata = [];

    const [onChangeTitle, setOnChangeTitle] = useState(false);
    const [newTitle, setNewTitle] = useState("");
    const [latestData, setLatestData] = useState("");
    const [chartL, setChartL] = useState("");
    const [axisL, setAxisL] = useState("");
    const [showGraph, setShowGraph] = useState(true)

    let maxValue = 0.0;
    let minValue = 0.0;
    let minGraph = 999;
    let maxGraph = 0;

    useEffect(() => {
        // Append Latest Data
        try {
            for (let i = 0; i < newSinglePropData.length; i++) {
                if (newSinglePropData[i]["parameter"] == parameter) {
                    let chartData = {
                        datetime: utcToMtyConvertionDate(newData[0]["datetime"]),
                        value: newData[0][parameter],
                        limit_low: newSinglePropData[i]['limit_low'],
                        limit_high: newSinglePropData[i]['limit_high'],
                        limit_low_parameter: newData[0][newSinglePropData[i]['limit_low_parameter']],
                        limit_high_parameter: newData[0][newSinglePropData[i]['limit_high_parameter']],
                    };
                    data.push(chartData);
                }
            }
        } catch (e) {
            console.log(e);
        }

        // Preprocess Data
        let finalData = [];
        for (let i = 0; i < data.length; i++) {
            if (data[i]["value"] != "NaN" && data[i]["datetime"].valueOf() >= new Date(new Date(minDate).getFullYear(), new Date(minDate).getMonth(), new Date(minDate).getDate(), 0, 0, 0).valueOf()) {
                finalData.push(data[i]);
            }
        }
        // console.log("*** LineChart finalData", finalData);
        if (finalData.length == 0) {
            setShowGraph(false)
            return
        }

        // Find Array Min Max Value
        let maxArray = [];
        maxArray[0] = getMax(finalData, "value");
        maxArray[1] = finalData[0]["limit_low_parameter"] == undefined || null ? 0 : getMax(finalData, "limit_low_parameter");
        maxArray[2] = finalData[0]["limit_high_parameter"] == undefined || null ? 0 : getMax(finalData, "limit_high_parameter");
        maxValue = maxArray.reduce(function (a, b) {
            return Math.max(a, b);
        });

        let minArray = [];
        minArray[0] = getMin(finalData, "value");
        minArray[1] = finalData[0]["limit_low_parameter"] == undefined ? 99 : getMin(finalData, "limit_low_parameter");
        minArray[2] = finalData[0]["limit_high_parameter"] == undefined ? 99 : getMin(finalData, "limit_high_parameter");
        minValue = minArray.reduce(function (a, b) {
            return Math.min(a, b);
        });

        am4core.addLicense("ch-custom-attribution");
        chart = am4core.create("chartdiv" + index, am4charts.XYChart);

        // Config
        chart.properties.paddingLeft = 0;

        // Create Axis
        dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.min = new Date(minDate).getTime();
        valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

        // Main Series
        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "value";
        series.dataFields.dateX = "datetime";
        series.strokeWidth = 1;
        series.minBulletDistance = 10;
        series.tooltip.pointerOrientation = "vertical";
        series.name = "Date";

        if (plot_limit == 1) {
            let range = valueAxis.axisRanges.create();
            range.value = limit_high;
            range.grid.stroke = am4core.color("red");
            range.grid.strokeDasharray = "1,1";
            range.grid.strokeWidth = 1;
            range.grid.strokeOpacity = 1;
            range.label.inside = true;
            range.label.fill = range.grid.stroke;
            range.label.verticalCenter = "bottom";

            let range2 = valueAxis.axisRanges.create();
            range2.value = limit_low;
            range2.grid.stroke = am4core.color("red");
            range2.grid.strokeDasharray = "1,1";
            range2.grid.strokeWidth = 1;
            range2.grid.strokeOpacity = 1;
            range2.label.inside = true;
            range2.label.fill = range2.grid.stroke;
            range2.label.verticalCenter = "bottom";

            if (parseFloat(maxValue) > parseFloat(limit_high)) {
                maxGraph = parseFloat(maxValue);
            } else {
                maxGraph = parseFloat(limit_high);
            }

            if (parseFloat(minValue) < parseFloat(limit_low)) {
                minGraph = parseFloat(minValue);
            } else {
                minGraph = parseFloat(limit_low);
            }
        } else {
            maxGraph = parseFloat(maxValue);
            minGraph = parseFloat(minValue);
        }

        if (data[0]["limit_low_parameter"] !== undefined) {
            let series_limit_high_parameter = chart.series.push(new am4charts.LineSeries());
            series_limit_high_parameter.dataFields.valueY = "limit_high_parameter";
            series_limit_high_parameter.dataFields.dateX = "datetime";
            series_limit_high_parameter.strokeWidth = 1;
            series_limit_high_parameter.strokeDasharray = "1,1";
            series_limit_high_parameter.stroke = series.stroke;
            series_limit_high_parameter.stroke = am4core.color("black");
            series_limit_high_parameter.name = "Set Point";

            let series_limit_low_parameter = chart.series.push(new am4charts.LineSeries());
            series_limit_low_parameter.dataFields.valueY = "limit_low_parameter";
            series_limit_low_parameter.dataFields.dateX = "datetime";
            series_limit_low_parameter.strokeWidth = 1;
            series_limit_low_parameter.strokeDasharray = "1,1";
            series_limit_low_parameter.stroke = series.stroke;
            series_limit_low_parameter.stroke = am4core.color("green");
            series_limit_low_parameter.name = "Low Set Point";

            series_limit_high_parameter.tooltipText = "[bold]Date:[/] {datetime.formatDate()}\n[bold]Actual Reading:[/] {value}\n[bold]Set Point:[/] {limit_high_parameter}\n" +
                "[bold]Low Set Point:[/] {limit_low_parameter}\n[bold]Alarm High:[/] {limit_high}\n[bold]Alarm Low:[/] {limit_low}";
            series_limit_high_parameter.tooltipContainer.fill = am4core.color("black");

            legenddata.push({
                name: "Actual Reading",
                fill: am4core.color("skyblue")
            });
            legenddata.push({
                name: "Set Point",
                fill: am4core.color("black")
            });
            legenddata.push({
                name: "Low Set Point",
                fill: am4core.color("green")
            });
            legenddata.push({
                name: "Alarm High/Low",
                fill: am4core.color("red")
            });
        } else {
            if (plot_limit == 1) {
                series.tooltipText = "[bold]Date:[/] {datetime.formatDate()}\n[bold]Value:[/] {value}\n[bold]Alarm High:[/] {limit_high}\n[bold]Alarm Low:[/] {limit_low}";
                legenddata.push({
                    name: "Actual Reading",
                    fill: am4core.color("skyblue")
                });
                legenddata.push({
                    name: "Alarm High/Low",
                    fill: am4core.color("red")
                });
            } else {
                series.tooltipText = "[bold]Date:[/] {datetime.formatDate()}\n[bold]Value:[/] {value}";
                legenddata.push({
                    name: "Actual Reading",
                    fill: am4core.color("skyblue")
                });
            }
        }

        valueAxis.min = minGraph;
        valueAxis.max = maxGraph;
        valueAxis.extraMin = 0.05;
        valueAxis.extraMax = 0.05;

        // Add cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = dateAxis;

        // Add legend
        chart.legend = new am4charts.Legend();
        chart.legend.data = legenddata;
        chart.legend.position = "bottom";

        // Customize legend marker
        // https://www.amcharts.com/docs/v4/concepts/legend/
        const marker = chart.legend.markers.template.children.getIndex(0);
        marker.cornerRadius(12, 12, 12, 12);
        const markerTemplate = chart.legend.markers.template;
        markerTemplate.width = 12;
        markerTemplate.height = 12;

        chart.legend.labels.template.fontSize = 9;

        chart.data = finalData;

        setChartL(chart);
        setAxisL(valueAxis);
        for (let i=data.length-1; i >= 0; i--) {
            if (data[i]['value']) {
                setLatestData(data[i]['value'])
                break
            }

        }
        setNewTitle(label);
    }, [minDate, newData]);

    useEffect(() => {
        if (newData.length > 0) {
            for (let i of newData) {
                if (i.sensor_set == sensor_set) {
                    setLatestData(i[parameter]);
                }
            }
        }
    }, [newData]);

    function onUpdateTitle(e) {
        e.preventDefault();
    }

    // customize width of LCD display box
    // values below are gotten by trial and error

    const valueText = `${(Math.round(latestData * 100) / 100).toFixed(2)}`;
    const unitText = `${unit}`;

    let customWidth = valueText.length * 20;

    if (unit) {
        customWidth += (unitText.length * 6);
    }

    const customLcdDisplay = <LcdDisplay
        type={"line"}
        index={index}
        unit={unit}
        latestData={latestData}
        width={customWidth}
    />;

    return (
        <>{showGraph && (
            <div className="card">
                <div className="card-body" style={{ paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }}>
                    <div className="container">
                        {!onChangeTitle && (
                            <div onClick={() => setOnChangeTitle(true)}>
                                <h3>{newTitle}</h3>
                            </div>
                        )}
                        {onChangeTitle && (
                            <form onSubmit={onUpdateTitle}>
                                <div className="form-group">
                                    <label>New Title</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id={"txt_" + label}
                                        placeholder="Enter New Title"
                                        value={newTitle}
                                        onChange={(e) => setNewTitle(e.target.value)}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-info"
                                        onClick={() => setOnChangeTitle(false)}
                                    >
                                        Back
                                    </button>
                                    <button type="submit" className="btn btn-primary" disabled={localStorage.getItem('demo_cloudatik')}>
                                        Change Title
                                    </button>
                                </div>
                            </form>
                        )}
                        {customLcdDisplay}
    
                        <BrowserView>
                            <div
                                style={{ width: "100%", height: "100%", minHeight: "300px" }}
                                id={"chartdiv" + index}
                            ></div>
                        </BrowserView>
                        <MobileView>
                            <div
                                style={{ width: "100%", height: "100%", minHeight: "300px" }}
                                id={"chartdiv" + index}
                            ></div>
                        </MobileView>
                    </div>
                </div>
            </div>
        )}</>
    );
}

export default LineChart;;;;;;;;;;
